.App {
  box-sizing: border-box;
}

.legend-wrapper {
  margin: 0 auto;
  margin-top: 20px;
  padding: 16px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

@media screen and (max-width: 600px) {
  .legend-wrapper {
    flex-direction: column;
  }
  .legend-item {
    margin-bottom: 16px;
  }
}
